import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/css/screen.css"
import { faFile, faGlobe, faHandPeace } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Trunkroad - We make B2B simple"
        description="Making B2B simple"
        keywords={[
          `B2B`,
          `B2B Commerce`,
          `AI Sales`,
          `Sales Intelligence`,
          "B2B eCommerce",
          "Sales Artificial Intelligence",
          "B2B AI",
          "B2B Sales AI",
        ]}
      />

      <div className="banner">
        <Img
          fluid={data.imageFile.childImageSharp.fluid}
          className="banner-img"
        />
        <div className="banner-body">
          <h1 className="caption">Making B2B simple</h1>
          <h4>
            At Trunkroad, we’re building one of the fastest growing platforms
            adopted by B2B companies all over the world.
          </h4>
        </div>
      </div>
      <div className="article text-center">
        <div className="section sec1">
          <div className="section-txt">
            <h3>About Us</h3>
            <h5 className="section-text">
              <p>
                We met B2B Sales teams across the world and experienced the time
                consuming processes involved with managing their day to day
                sales. We realized we could make it easier for companies to get
                the job done while maintaining happy employees. So we launched
                Trunkroad, an AI powered B2B commerce platform with a focus on
                sales teams.
              </p>
              <h4>Go ahead and get in touch. We would love to help you!</h4>
            </h5>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    imageFile: file(relativePath: { eq: "HeroAbout.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
